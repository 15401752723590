
import React from 'react';
import awardsData from '../data/awardsData';

function Awards() {
  return (
    <section>
      <div class="w-800">
        <div class="page_header">
          <h2>
            AWARDS
            <div class="title_ja">受賞・入選</div>
          </h2>
        </div>
        <ul class="awards grid gap-1rem">
          {awardsData.map((award, index) => (
            <li key={award.id}>
              <div
                class="award_title"
              >
                {award.title} <span class="award_year">({award.year})</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default Awards;